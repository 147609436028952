import * as service from 'services/actionSpecs';
import * as serviceSurvey from 'services/surveys';
import * as data from 'data/actionSpecs';
import * as surveysTypes from 'data/surveys';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import ActionSpecFactory from 'factories/actionSpecs';

const requestActionSpec = () => ({
  type: data.TYPE_REQUEST_ACTION_SPEC,
});

const receiveActionSpec = (json) => ({
  type: data.TYPE_RECEIVE_ACTION_SPEC,
  actionSpec: json.actionSpec,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions,
});

const receiveActionSpecError = (error) => ({
  type: data.TYPE_RECEIVE_ACTION_SPEC_ERROR,
  error: error,
});

const postCreateActionSpec = (actionSpec) => ({
  type: data.TYPE_CREATE_ACTION_SPEC,
  actionSpec,
});

const postCreateActionSpecSuccess = (actionSpec) => ({
  type: data.TYPE_CREATE_ACTION_SPEC_SUCCESS,
  actionSpec: ActionSpecFactory.toModel(actionSpec),
});

const saveLanguageFileSuccess = () => ({
  type: data.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_SUCCESS,
});

const saveLanguageFileError = () => ({
  type: data.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_ERROR,
});

const postLanguageFile = () => ({
  type: data.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE,
});

const requestLanguageFile = () => ({
  type: data.TYPE_REQUEST_LANGUAGE_FILE,
});

const postCreateActionSpecError = (actionSpec, error) => ({
  type: data.TYPE_CREATE_ACTION_SPEC_ERROR,
  error,
  actionSpec,
});

const postUpdateActionSpec = (actionSpec) => ({
  type: data.TYPE_UPDATE_ACTION_SPEC,
  actionSpec,
});

const postUpdateActionSpecSuccess = (json) => ({
  type: data.TYPE_UPDATE_ACTION_SPEC_SUCCESS,
  tenantsConflicts: [],
  actionSpec: ActionSpecFactory.toModel(json.data),
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions,
});

const postUpdateActionSpecError = (error) => ({
  type: data.TYPE_UPDATE_ACTION_SPEC_ERROR,
  error,
});

export const resetActionSpec = () => ({
  type: data.TYPE_RESET_ACTION_SPEC,
});

const receiveSurveys = (json) => ({
  type: surveysTypes.TYPE_RECEIVE_SURVEYS,
  isFetching: false,
  surveys: json.data.surveys,
});

const requestSurveys = () => ({
  type: surveysTypes.TYPE_REQUEST_SURVEYS,
  isFetching: true,
});

const receiveSurveysError = (error) => ({
  type: surveysTypes.TYPE_RECEIVE_SURVEYS_ERROR,
  isFetching: false,
  error: error.message ? error.message : error,
});

export const fetchSurvey = (searchText = null, filter_tenants = false) => async (dispatch) => {
  let filter = (searchText) ? { 'searched text': [searchText] } : {};
  dispatch(requestSurveys());
  return await serviceSurvey
      .fetchSurveys(filter, -1, filter_tenants)
      .then((surveys) => dispatch(receiveSurveys(surveys)))
      .catch((error) => dispatch(receiveSurveysError(error)));
};

export const fetchActionSpec = (id) => {
  return (dispatch) => {
    dispatch(requestActionSpec());
    return service
      .fetchActionSpec(id)
      .then((response) => dispatch(receiveActionSpec(response)))
      .catch((error) => dispatch(receiveActionSpecError(error)));
  };
};

export const getLanguageFile = (actionSpecId, languageCode) => {
  return async (dispatch) => {
    dispatch(requestLanguageFile());
    const result = await service.getLanguageFile(actionSpecId, languageCode);
    return result;
  };
};

export const getLanguageFileTemplate = (actionSpecId) => {
  return async (dispatch) => {
    dispatch(requestLanguageFile());
    const result = await service.getLanguageFileTemplate(actionSpecId);
    return result;
  };
};

export const saveLanguageFile = (file, actionSpecId, languageCode) => {
  return async (dispatch) => {
    dispatch(postLanguageFile());
    return service
      .postLanguageFile(file, actionSpecId, languageCode)
      .then(() => dispatch(saveLanguageFileSuccess()))
      .catch(() => dispatch(saveLanguageFileError()));
  };
};

export const saveMessageCSVFile = (file, actionSpecId, category, successCallback) => {
  return async (dispatch) => {
    dispatch(postLanguageFile());
    return service
      .postMessageCSV(file, actionSpecId, category)
      .then(() => dispatch(successCallback()))
      .catch(() => dispatch(saveLanguageFileError()));
  };
};

export const saveMessageCsv = (file, actionSpecId, category) => {
  return async (dispatch) => {
    return service.postMessageCSV(file, actionSpecId, category)
      .then(() => dispatch(saveLanguageFileSuccess()))
      .catch(() => dispatch(saveLanguageFileError()));
  };
};

export const getMessageCsv = (actionSpecId, category) => {
  return async (dispatch) => {
    dispatch(requestLanguageFile());
    const result = await service.fetchMessageCsv(actionSpecId, category);
    return result;
  };
};

export const createActionSpec = (actionSpec) => {
  return (dispatch) => {
    dispatch(postCreateActionSpec(actionSpec));
    return service
      .createActionSpec(actionSpec)
      .then((response) => {
        dispatch(postCreateActionSpecSuccess(response.data));
        dispatch(push(`${response.data.id}/edit`));
      })
      .catch((error) => dispatch(postCreateActionSpecError(actionSpec, error)));
  };
};

export const updateActionSpec = (actionSpec) => {
  return (dispatch) => {
    dispatch(postUpdateActionSpec(actionSpec));
    return service
      .updateActionSpec(actionSpec)
      .then((response) => {
        dispatch(postUpdateActionSpecSuccess(response));
      })
      .catch((error) => dispatch(postUpdateActionSpecError(error)));
  };
};
