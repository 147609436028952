import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { fetchUserTenantsIfNeeded } from '../../../actions/tenants';
import { UserTenantSelector } from 'views';

const mapStateToProps = ({ tenants }) => ({ tenants });
const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        push,
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchUserTenantsIfNeeded());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTenantSelector);
