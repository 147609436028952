export const TYPE_REQUEST_TENANTS = 'tenants/REQUEST_TENANTS';
export const TYPE_RECEIVE_TENANTS = 'tenants/RECEIVE_TENANTS';
export const TYPE_REQUEST_USER_TENANTS = 'tenants/REQUEST_USER_TENANTS';
export const TYPE_RECEIVE_USER_TENANTS = 'tenants/RECEIVE_USER_TENANTS';

export const CHECK_BOX_MARGIN = '5px';
export const TENANT_NAME_MARGIN = '10px';
export const TENANT_INCLUDE = 'Include';
export const TENANT_EXCLUDE = 'Exclude';

export const TENNANTS_MESSAGE = `A tenant is a segment of the all.health service and a segment 
of all.health users. Each tenant is organized according to the group managing the users' 
care and the types of care and content the users receive within the app. For a user, the tenant is 
associated with the code entered at sign-up.`;
