import * as service from 'services/tenants';

import {
  TYPE_RECEIVE_TENANTS,
  TYPE_RECEIVE_USER_TENANTS,
  TYPE_REQUEST_TENANTS,
  TYPE_REQUEST_USER_TENANTS,
} from 'data/tenants';

import isEmpty from 'lodash/isEmpty';
import store from 'config/store';

const requestTenants = () => ({ type: TYPE_REQUEST_TENANTS, isFetching: true });
const requestUserTenants = () => ({ type: TYPE_REQUEST_USER_TENANTS, isUserTenantsFetching: true });

const receiveTenants = (json) => ({
  type: TYPE_RECEIVE_TENANTS,
  tenants: json.map((tenant) => ({
    id: tenant.tid,
    name: tenant.common_name,
  })),
  isFetching: false,
});

const receiveUserTenants = (json) => ({
  type: TYPE_RECEIVE_USER_TENANTS,
  userTenants: json.map((tenant) => {
    return {
      id: tenant.tenantUuid,
      name: tenant.tenantName,
    };
  }),
  isUserTenantsFetching: false,
});

const fetchTenants = () => {
  return (dispatch) => {
    dispatch(requestTenants());
    return service
      .fetchTenants()
      .then((response) => dispatch(receiveTenants(response)))
      .catch((error) => {
        console.error(error);
      });
  };
};

const fetchUserTenants = (userId) => {
  return (dispatch) => {
    dispatch(requestUserTenants());
    return service
      .fetchUserTenants(userId)
      .then((response) => dispatch(receiveUserTenants(response)))
      .catch((error) => {
        console.error(error);
      });
  };
};

export const fetchTenantsIfNeeded = () => {
  return (dispatch) => {
    const state = store.getState();
    const tenants = state.tenants;
    const isFetching = tenants.isFetching;
    if (isEmpty(tenants.tenants) && !isFetching) {
      return dispatch(fetchTenants());
    }
  };
};

export const fetchUserTenantsIfNeeded = () => {
  return (dispatch) => {
    const state = store.getState();
    const userId = state.auth.user.uid;
    const tenants = state.tenants;
    const isFetching = tenants.isUserTenantsFetching;
    if (isEmpty(tenants.userTenants) && !isFetching) {
      return dispatch(fetchUserTenants(userId));
    }
  };
};

export default fetchTenantsIfNeeded;
