import React, { useEffect, useState } from 'react';
import { LogoAllIcon } from 'assets/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  Stack,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Footer } from 'components/commons';

import useStyles from './UserTenantSelector.styles';

const UserTenantSelector = ({ tenants, actions }) => {
  const classes = useStyles();
  const history = useHistory();
  const userTenants = tenants.userTenants;
  const [tenantIdSelected, setTenantSelected] = useState(localStorage.getItem('userTenantId'));

  const handleSelectUserTenant = (event) => {
    const userTenantId = event?.target?.value;
    if (userTenantId) {
      setTenantSelected(userTenantId);
    }
  };

  const handleSelect = () => {
    if (tenantIdSelected) {
      localStorage.setItem('userTenantId', tenantIdSelected);
      history.push('/home');
    }
  };

  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100vh' }}>
      <Stack className={classes.container} gap="36px">
        <Stack justifyContent="center" alignItems="center" gap="36px">
          <LogoAllIcon className={classes.logo} />
          <Typography variant="header19" className={classes.title}>
            Select Tenant
          </Typography>
        </Stack>
        <FormControl className={classes.root}>
          {!tenantIdSelected && (
            <InputLabel id="select-label" variant="outlined">
              Select tenant
            </InputLabel>
          )}

          <Select
            onChange={handleSelectUserTenant}
            label={!tenantIdSelected ? 'Select tenant' : undefined}
          >
            {userTenants.map((option) => {
              return (
                <MenuItem value={option.id} key={option.id}>
                  <Typography variant="para12">{option.name}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          onClick={handleSelect}
          classes={{ root: classes.button }}
          disabled={!tenantIdSelected}
          fullWidth
        >
          Select Tenant
        </Button>
      </Stack>
      <Footer isWhiteBackground />
    </Stack>
  );
};

export default UserTenantSelector;
