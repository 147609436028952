import React, { useEffect, useState } from 'react';
import TenantsIdSelector from 'components/commons/tenants-id-selector';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import useStyles from './Home.styles';

const Home = ({ actions, tenants }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const [selectedTenant, setSelectedTenantForm] = useState();

  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTenantSelect = (e) => {
    setSelectedTenantForm(e.target.value);
  };

  const handleSubmit = () => {
    localStorage.setItem('tenantId', selectedTenant);
    localStorage.setItem('tenantName', tenants.tenants.find((t) => t.id === selectedTenant).name);
    history.push('/insights');
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Aristotle Content Management System</Typography>
      <TenantsIdSelector
        handleChange={handleTenantSelect}
        onSubmit={handleSubmit}
        selectedTenant={selectedTenant}
        tenants={tenants.tenants}
      />
    </div>
  );
};

export default Home;
