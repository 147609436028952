import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  container: {
    width: '432px',
    minHeight: '437px',
    backgroundColor: theme.palette.custom.greyB,
    borderRadius: '12px',
    padding: '72px 48px',
  },
  logo: {
    width: '80px',
    height: '63px',
  },
  title: {
    fontSize: 19,
    fontWeight: 700,
    color: theme.palette.custom.black,
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      boxShadow: '0px 15px 18px -11px rgba(80, 86, 97, 0.2)',
      borderRadius: '3px',
      padding: '9px 18px',
      transition: 'none',
      position: 'relative',
      zIndex: 0,
      color: theme.palette.custom.white,
      background: theme.palette.custom.black,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        opacity: 0,
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        zIndex: -1,
      },
      '&:hover': {
        '&.MuiButton-root': {
          background: theme.palette.custom.black,
        },
        '&::before': {
          background: theme.palette.custom.black,
          opacity: 1,
        },
      },
      '&.Mui-disabled': {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
        boxShadow: 'none',
      },
    },

    height: '48px',
  },
}));
