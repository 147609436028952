import React from 'react';

import {
  ActionSpecsContainer,
  CreateActionSpecContainer,
  EditActionSpecContainer,
  CreateInsightContainer,
  CreateLabelContainer,
  CreateModuleContainer,
  CreateObservationContainer,
  CreatePledgeContainer,
  CreateProgramContainer,
  CreateSurveyContainer,
  EditInsightContainer,
  EditLabelContainer,
  EditModuleContainer,
  EditObservationContainer,
  EditPledgeContainer,
  EditProgramContainer,
  EditSurveyContainer,
  HomeContainer,
  InsightsContainer,
  LabelsContainer,
  LoginContainer,
  PledgesContainer,
  PreviewsContainer,
  ProgramsContainer,
  ReportsContainer,
  SummaryContainer,
  SurveysContainer,
} from 'containers/views';
import { Route, Switch, useLocation } from 'react-router-dom';
import { HomeTemplateContainer } from 'containers/templates';
import { UserTenantSelector } from 'containers/views';

const Routes = (routerProps) => {
  const { isAuthed } = routerProps;
  const userData = localStorage.getItem('userData');
  const userTenantId = localStorage.getItem('userTenantId');
  const location = useLocation();

  if (!isAuthed && userData === null) {
    localStorage.setItem('prevPage', window.location.pathname);
    return <LoginContainer {...routerProps} />;
  }

  if (isAuthed && userData && !userTenantId) {
    return <UserTenantSelector />;
  }

  if (String(location.pathname).includes('/previews/')) {
    return <PreviewsContainer {...routerProps} />;
  }

  return (
    <Switch>
      <HomeTemplateContainer>
        <Route exact path="/home" component={HomeContainer} {...routerProps} />
        <Route exact path="/insights" component={InsightsContainer} {...routerProps} />
        <Route exact path="/insights/create" component={CreateInsightContainer} {...routerProps} />
        <Route exact path="/insights/:id/edit" component={EditInsightContainer} {...routerProps} />
        <Route exact path="/labels" component={LabelsContainer} {...routerProps} />
        <Route exact path="/labels/create" component={CreateLabelContainer} {...routerProps} />
        <Route exact path="/labels/:id/edit" component={EditLabelContainer} {...routerProps} />
        <Route exact path="/pledges" component={PledgesContainer} {...routerProps} />
        <Route exact path="/pledges/create" component={CreatePledgeContainer} {...routerProps} />
        <Route exact path="/pledges/:id/edit" component={EditPledgeContainer} {...routerProps} />
        <Route exact path="/surveys" component={SurveysContainer} {...routerProps} />
        <Route exact path="/surveys/create" component={CreateSurveyContainer} {...routerProps} />
        <Route exact path="/surveys/:id/edit" component={EditSurveyContainer} {...routerProps} />
        <Route exact path="/programs" component={ProgramsContainer} {...routerProps} />
        <Route exact path="/programs/create" component={CreateProgramContainer} {...routerProps} />
        <Route exact path="/programs/:id/edit" component={EditProgramContainer} {...routerProps} />
        <Route exact path="/action-specs" component={ActionSpecsContainer} {...routerProps} />
        <Route
          exact
          path="/action-specs/create"
          component={CreateActionSpecContainer}
          {...routerProps}
        />
        <Route
          exact
          path="/action-specs/:id/edit"
          component={EditActionSpecContainer}
          {...routerProps}
        />
        <Route exact path="/reports" component={ReportsContainer} {...routerProps} />
        <Route
          exact
          path="/programs/:id/modules/create"
          component={CreateModuleContainer}
          {...routerProps}
        />
        <Route exact path="/modules/:id/edit" component={EditModuleContainer} {...routerProps} />
        <Route
          exact
          path="/observations/create"
          component={CreateObservationContainer}
          {...routerProps}
        />
        <Route
          exact
          path="/observations/:id/edit"
          component={EditObservationContainer}
          {...routerProps}
        />
        <Route exact path="/summary" component={SummaryContainer} {...routerProps} />
      </HomeTemplateContainer>
    </Switch>
  );
};

export default Routes;
