import {
  TYPE_RECEIVE_TENANTS,
  TYPE_REQUEST_TENANTS,
  TYPE_RECEIVE_USER_TENANTS,
  TYPE_REQUEST_USER_TENANTS,
} from '../data/tenants';

export const initState = {
  tenants: [],
  userTenants: [],
  isUserTenantsFetching: false,
  isFetching: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_TENANTS:
      return { ...state, isFetching: true };

    case TYPE_RECEIVE_TENANTS:
      return {
        ...state,
        tenants: action.tenants,
        isFetching: false,
      };

    case TYPE_REQUEST_USER_TENANTS:
      return { ...state, isUserTenantsFetching: true };

    case TYPE_RECEIVE_USER_TENANTS:
      return {
        ...state,
        userTenants: action.userTenants,
        isUserTenantsFetching: false,
      };

    default:
      return state;
  }
};
