import { authorizedAxios } from 'config/axios';

const FACTUARY_URL = '/factuary/api/v1';

export const fetchTenants = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/tenants`);
};

export const fetchUserTenants = (userId) => {
  return authorizedAxios.get(`/user/api/v3/users/clinicians/${userId}/tenants`);
};
