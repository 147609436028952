import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Select from 'components/commons/select';
import TitleInput from 'components/views/surveys/title-input';
import Params from 'components/commons/params-field';
import TestParams from 'components/commons/tests-field/Params';
import useTestsStyles from 'components/commons/tests-field/TestsField.styles';
import { useDebounce } from 'utils/hooks';

import { BlocklyConditionsAndTests, TenantsField } from 'components/commons';
import {
  ACTION_SPEC_ACTION_CATEGORY_OPTIONS,
  ACTION_SPEC_PRIORITY_OPTIONS,
  ACTION_SPEC_STATUS_OPTIONS,
  ACTION_SPEC_TRIGGER_WAY_OPTIONS,
} from 'data/actionSpecs';
import { LANGUAGES } from 'data/shared';

const languageOptions = Object.keys(LANGUAGES).map((item) => {
  return { value: item, text: LANGUAGES[item].name };
});

const GeneralForm = ({
  form,
  setForm,
  handleChange,
  nonEditableName,
  handleTenantCheck,
  tenants,
  facts,
  handleConditions = () => {},
  handleBlocklyCondition = () => {},
}) => {
  const testsClasses = useTestsStyles();
  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleExternalChange = () => {
    const newState = {
      conditions: [...form.tests.conditions],
      params: debouncedParams.map((param) => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };

  return (
    <Box p={4}>
      <TitleInput
        value={form.title}
        onChange={handleChange('title')}
        placeholder="Title"
        required
        disabled={nonEditableName}
        readOnly={nonEditableName}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Type"
            type="text"
            value={form.type}
            onChange={handleChange('type')}
            margin="normal"
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Determines the type of the action" placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            margin="normal"
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="The name of the action, unique among all action specs"
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Select
            label="Action Category"
            onChange={handleChange('actionCategory')}
            value={form.actionCategory}
            options={ACTION_SPEC_ACTION_CATEGORY_OPTIONS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid container direction="row" alignItems="baseline" wrap="nowrap" item xs={12} sm={4}>
          <Select
            label="Priority"
            onChange={handleChange('priority')}
            value={form.priority}
            options={ACTION_SPEC_PRIORITY_OPTIONS}
            fullWidth
            required
          />
          <Tooltip
            title="Determines section placement: LOW priority actions appear in Standard section, all other - in Priority section."
            placement="top-start"
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Language"
            id="lang"
            onChange={(e) => handleChange('lang')(e)}
            value={form.lang}
            options={languageOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Select
            label="Trigger way"
            onChange={handleChange('flags')}
            value={form.flags}
            options={ACTION_SPEC_TRIGGER_WAY_OPTIONS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={ACTION_SPEC_STATUS_OPTIONS}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="number"
            value={form.ttl}
            onChange={handleChange('ttl')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the action-spec should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="number"
            value={form.cooldown}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the action-spec for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Parameters
          </Typography>
          <Params handleChange={handleChange('params')} initialValue={form.params} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Tests for params
          </Typography>
          <TestParams
            classes={testsClasses}
            onChange={setParams}
            initialValue={form.tests ? form.tests.params ?? [] : []}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BlocklyConditionsAndTests
            content={form}
            handleChange={handleConditions}
            blocklyHandleChange={handleBlocklyCondition}
            facts={facts}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <div>
            <TenantsField
              handleTenantCheck={handleTenantCheck}
              initialValue={form.tenants}
              tenantList={tenants}
              form={form}
              setForm={setForm}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralForm;
