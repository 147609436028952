import React, { useState } from 'react';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { hasDuplicates } from 'utils/commons';

import { AppBar, Container, Paper, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';

import useStyles from './Form.styles';
import GeneralForm from './GeneralForm';
import EvidenceForm from './EvidenceForm';
import TasksForm from './TasksForm';
import MessagesForm from './MessagesForm';
import TranslationsForm from './TranslationsForm';

const tabs = [GeneralForm, EvidenceForm, TasksForm, MessagesForm, TranslationsForm];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {children}
  </Typography>
);

const ActionSpecForm = ({
  form,
  setForm,
  handleChange,
  handleFetchSurvey,
  handleFileDownload,
  handleTemplateFileDownload,
  handleFileUpload,
  handleStatusChange,
  handleTenantCheck,
  handleMessageCsvDownload,
  handleMessageCsvUpload,
  tenants,
  onSubmit,
  nonEditableName = false,
  testResults,
  facts,
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(null);

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleConditions =
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        setForm({
          ...form,
          [name]: value,
        });
      }
    };

  const handleBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach((condition) => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }

    setForm(newForm);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Form onSubmit={handleSubmit}>
        <Paper>
          <AppBar className={classes.tabs} elevation={0} position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Actin Spec Form Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Evidence" {...a11yProps(1)} />
              <Tab label="Tasks" {...a11yProps(2)} />
              <Tab label="Messages" {...a11yProps(3)} />
              <Tab label="Translations" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          {tabs.map((TabComponent, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              <TabComponent
                form={form}
                setForm={setForm}
                handleChange={handleChange}
                handleConditions={handleConditions}
                handleBlocklyCondition={handleBlocklyCondition}
                handleTenantCheck={handleTenantCheck}
                handleFetchSurvey={handleFetchSurvey}
                handleFileDownload={handleFileDownload}
                handleTemplateFileDownload={handleTemplateFileDownload}
                handleMessageCsvDownload={handleMessageCsvDownload}
                handleMessageCsvUpload={handleMessageCsvUpload}
                handleStatusChange={handleStatusChange}
                handleFileUpload={handleFileUpload}
                tenants={tenants.tenants}
                nonEditableName={nonEditableName}
                setError={setError}
                testResults={testResults}
                type="action-spec"
                facts={facts.facts}
              />
            </TabPanel>
          ))}
          <section className={classes.saveButtonContainer}>
            <Tooltip title={error ?? ''} placement="top">
              <div>
                <Button color="primary" variant="contained" type="submit" size="medium" disabled={!!error}>
                  Save
                </Button>
              </div>
            </Tooltip>
          </section>
        </Paper>
      </Form>
    </Container>
  );
};

ActionSpecForm.propTypes = {
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleFetchSurvey: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleTenantCheck: PropTypes.func,
  tenants: PropTypes.object,
  testResults: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default ActionSpecForm;
